<template>
  <div id='main'>
    <el-form inline id="content" ref="k3Params" :model="k3Params">
      <div id="form">
        <el-form-item label="任务id:" style=" margin-top: 20px 0" label-width="128px" prop="job_id">
            <el-input inline v-model="k3Params.job_id " @keyup.enter.native="queryList" style="width:200px"  clearable placeholder="请输入任务id"></el-input>
        </el-form-item> 
         <el-form-item label="订单号:"  prop="platform_code">
            <el-input  v-model="k3Params.platform_code" @keyup.enter.native="queryList" style="width:238px" clearable placeholder="请输入订单号"></el-input>
        </el-form-item>
         <div id="foldBox">
         <el-form-item label="任务开始时间:" label-width="130px" prop="operator">
          <el-select style="width:125px" placeholder="请选择运算符" v-model="k3Params.start_time.operator" clearable>
            <el-option 
              v-for="item in operator"
              :key="item.id"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item   prop="value">
          <el-date-picker
            v-model="k3Params.start_time.value"
            style="width:370px"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item><br>
        <el-form-item label="任务结束时间:"  label-width="130px" prop="operator">
          <el-select style="width:125px" placeholder="请选择运算符"  v-model="k3Params.end_time.operator" clearable>
            <el-option 
              v-for="item in operator"
              :key="item.id"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item prop="value">
          <el-date-picker
             v-model="k3Params.end_time.value"
            style="width:370px"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item> 
        </div>
        <el-form-item  id="button">
          <el-button type="primary" icon="el-icon-search" @click="queryList" v-preventReClick>查询</el-button>
          <el-button icon="el-icon-refresh" v-preventReClick @click="resetForm ">重置</el-button>
          <a  class="advanced" @click="toggleAdvanced">
                {{ advanced ? '展开' : '收起' }}
            <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down' "></i>
          </a>
        </el-form-item>
       </div>
         <el-divider></el-divider>
        <el-table 
            :data="records" 
            id="Table"
            border
            style="width: 98%" 
            :header-cell-style="headClass"
            v-loading="pictLoading" 
            element-loading-background="rgb(255,255,255))"
            element-loading-text="数据加载中"
            element-loading-spinner="el-icon-loading">
            <el-table-column fixed type="index" width="50" align="center" label="#"></el-table-column>
            <el-table-column fixed  prop="job_id" label="任务id"  show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <span @click="handleCopyLink(scope.row)" style="color:#000">{{scope.row.job_id}} </span>
              </template>
            </el-table-column>
            <el-table-column fixed prop="platform_code" label="平台订单号" show-overflow-tooltip min-width="180px" align="center"></el-table-column>
            <el-table-column prop="document_type" label="单据类型" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <el-tag type="info"> {{scope.row.document_type}} </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="start_time" label="任务开始窗口时间" show-overflow-tooltip min-width="150px" align="center"></el-table-column>
            <el-table-column prop="end_time" label="任务结束窗口时间" show-overflow-tooltip min-width="150px" align="center"></el-table-column>
            <el-table-column prop="sku_code" label="sku编码" show-overflow-tooltip min-width="120px" align="center"></el-table-column>
            <el-table-column prop="order_qty"  label="sku个数" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="accounts_payable" label="应付金额" show-overflow-tooltip align="center">
              <template slot-scope="scope">{{'¥'+scope.row.accounts_payable}}</template>
            </el-table-column>
            <el-table-column prop="shop_code" label="店铺code" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="document_id" label="单据编号" show-overflow-tooltip min-width="120px" align="center"></el-table-column>
            <el-table-column prop="create_time" label="单据写入日期" min-width="100px" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="sales_unit_price" label="销售单价" show-overflow-tooltip align="center">
               <template slot-scope="scope">{{'¥'+scope.row.sales_unit_price}}</template>
            </el-table-column>
            <el-table-column prop="sales_amount" label="销售金额" show-overflow-tooltip align="center">
               <template slot-scope="scope">{{'¥'+scope.row.sales_amount}}</template>
            </el-table-column>
            <el-table-column prop="order_type" label="订单类型" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="item_code" label="产品长代码" show-overflow-tooltip min-width="120px" align="center"></el-table-column>
            <el-table-column prop="item_name" label="产品名称" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="sale_qty" label="实发数量" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="unit" label="单位" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="audit_flag" label="审核标志" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="void_sign" label="作废标志" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="sell_way" label="销售方式" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="purchasing_unit" label="购货单位" show-overflow-tooltip min-width="100px" align="center"></el-table-column>
            <el-table-column prop="shipping_warehouse" label="发货仓库" show-overflow-tooltip  min-width="120px" align="center"></el-table-column>
            <el-table-column prop="shipper" label="发货人" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="custodian" label="保管人" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="order_maker" label="制单人" show-overflow-tooltip  min-width="100px" align="center"></el-table-column>
            <el-table-column prop="reviewer" label="审核人" show-overflow-tooltip  min-width="100px" align="center"></el-table-column>
            <el-table-column prop="review_date" label="审核日期" show-overflow-tooltip min-width="120px" align="center"></el-table-column>
            <el-table-column prop="payment_date" label="收款日期" show-overflow-tooltip min-width="120px" align="center"></el-table-column>
            <div slot="empty">
              <p>
                <img src="https://ysf.nosdn.127.net/huke/501809b24ca822a32d8792d0e6872b3f" />
              </p>
              <p><span>暂无数据</span></p>
				    </div>
        </el-table>
        <div>
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="k3Params.current_page"
          :limit.sync="k3Params.page_size"
          @pagination="FinancialData"
        />
    </div>
     </el-form>
  </div>
</template>

<script>
import {errorAlert,successAlert}from "../../../utils/alert"
export default {
    data(){
        return{
        pictLoading:false,
         records:[],
         total:0,
         value:'',
         value1:'',
        k3Params:{
          current_page:1,
          page_size:10,
          job_id:'',
          platform_code:'',
          start_time:{
            operator:'',
            value:'',
          },
          end_time:{
            operator:'',
            value:'',
          },
        },
         advanced:false,
        operator:[
            { id: '1',label: '>'}, 
            { id: '2',label: '<'}, 
            { id: '3',label: '='}, 
            { id: '4',label: '>='}, 
            { id: '5',label: '<='},
            { id: '6', label:'!='}
            ],
        };  
    },

    mounted() {
      let userInfo = sessionStorage.getItem("userInfo");
          if(userInfo){
            this.FinancialData();
          }
      this.$nextTick(function() {
        this.toggleAdvanced();
      });
  },

    methods:{
      handleCopyLink(row) {
        let _this = this;
        this.$copyText(row.job_id).then(function (e) {
          _this.$message({
            showClose: true,
            message: "复制成功",
            type: "success"
          });
        }, function (e) {
          _this.$message({
            showClose: true,
            message: "复制失败，请手动复制",
            type: "error"
          });
        })
      },

     toggleAdvanced(){
        this.advanced = !this.advanced;
        var foldBoxHeight= document.getElementById("foldBox");
        if (this.advanced == true) {
          foldBoxHeight.style.height = "auto";
        document.getElementById("foldBox").style.display='none';
        document.getElementById("button").style.marginTop='0'
        } else {
          document.getElementById("foldBox").style.display='inline-block';
          document.getElementById("button").style.marginTop='50px'
        }
     },

      headClass() {
        return 'background: #f5f5f7;border-bottom:none;color:rgba(0,0,0,.85)'
      },

      async  FinancialData(){
         this.pictLoading = true
        let obj=JSON.parse(JSON.stringify(this.k3Params))
            this.compactObj(obj, this.isEmpty) 
            let params={
              ...obj,
            };
          const res= await this.$http.post("/k3_records/get",params);
            this.pictLoading = false
          if(res.data.code == 0){
            this.records=res.data.data.records;
            this.total=res.data.data.total;
          }else{
            errorAlert(res.data.msg)
          }
        },
         
      queryList(){
         this.k3Params.current_page=1
        this.FinancialData()
      },
  
      resetForm(){
         this.k3Params=this.$options.data().k3Params;
          this.queryList()
      },

    compactObj (obj, fn) {
      for (var i in obj) {
        if (typeof obj[i] === 'object') {
         this.compactObj(obj[i], fn)
        }
        if (fn(obj[i])) {
          delete obj[i]
        }
      }
},

    isEmpty (foo) {
      if (typeof foo === 'object') {
        for (var i in foo) {
          return false
        }
        return true
      } else {
        return foo === '' || foo === null || foo === undefined
      }
    },
    }   
}
</script>

<style lang="scss" scoped>

#form{
  margin-bottom: -15px;
  padding-top: 20px;
  box-sizing: border-box;
}
 #main{
    width: 100%;
    height: 100%;
    background: #fff;
}
/deep/.el-table{
  color: #000;
   margin-left:10px ;
   margin-top: 20px;
}
.el-divider--horizontal{
  margin: 10px 15px !important;
  background: #EBEEF5;
  width: 98%;
}
/deep/.el-table__empty-text {
    line-height: 0; 
    width: 50%;
    color: #909399;
}
#foldBox{
  overflow: hidden;
  height: 100px;
}
.advanced{
  margin-left:8px;
  color: #409EFF;
  display: inline-block; 
}
.el-tag.el-tag--info {
    background-color: #fff;
    border-color: #e9e9eb;
    color: #000;
}
</style>>

